import { template as template_92b53d153ffd44e68c3e5b28ef0c2323 } from "@ember/template-compiler";
const WelcomeHeader = template_92b53d153ffd44e68c3e5b28ef0c2323(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
