import { template as template_b8d51740f2374a5eb9c2e2bafd9168e7 } from "@ember/template-compiler";
const FKLabel = template_b8d51740f2374a5eb9c2e2bafd9168e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
